import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import Header from "../components/header/header"
import GetImgUrl from "../utils/customFunctions"


// import { Helmet } from "react-helmet"
// import RichSnippet from "../components/rich-snippet-component/rich-snippet"
// import useSiteMetadata from "../utils/useSiteMetadata"


// import loadable from '@loadable/component';
import TextImageComponent from "../components/text-image-component/text-image-component"
import TextComponent from "../components/text-component/text-component"
import ContactComponent from "../components/contact-component/contact-component"
import Modal from "../components/modal/modal"
// const DynamicComponent = loadable((props) => import(`../components/${props.component}/${props.component}`));




// markup
const IndexPage = ({ pageContext }) => {

  const data = useStaticQuery(graphql`
        {
          strapiHomepage {
            PageComponents
            seo {
              metaTitle
              metaDescription
            }
          }
        }
        `
  )



  return (
    <Layout SEOTitle={data.strapiHomepage.seo.metaTitle} SEODescription={data.strapiHomepage.seo.metaDescription}>

      {data.strapiHomepage?.PageComponents?.map((item, index) => {


        switch (item.strapi_component) {

          case "page-components.header":
            return (
              <>

                <Header
                  key={index}
                  Title={item.Title !== null ? item.Title : null}
                  Subtitle={item.Subtitle}
                  FirstCTATitle={item.FirstCTATitle}
                  FirstCTAURL={item.FirstCTAUrl}
                  SecondCTATitle={item.SecondCTATitle}
                  SecondCTAURL={item.SecondCTAUrl}
                  ThirdCTATitle={item.ThirdCTATitle}
                  ThirdCTAURL={item.ThirdCTAUrl}
                  Background={GetImgUrl(item.Background?.localFile___NODE, pageContext.images)}
                  Icon={item.Icon !== null ? GetImgUrl(item.Icon.localFile___NODE, pageContext.images) : null}
                  HeaderHeight={item.HeaderHeight}
                  FooterInformation={item.FooterInformation !== null ? item.FooterInformation : null}
                  ReservationModal={item.ReservationModal !== null ? item.ReservationModal : null}
                ></Header>
              </>

            )

          case "page-components.text-image-component":
            return (
              <>

                <TextImageComponent
                  Image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                  ImagePosition={item.ImagePosition}
                  Title={item.Title}
                  Description={item.Description}
                  Button={item.Button} />
              </>
            )

          case "page-components.text-component":
            return (
              <>

                <TextComponent
                  Title={item.Title}
                  Description={item.Description}
                  Button={item.Button}
                  BackgroundColor={item.BackgroundColor} />
              </>
            )
          case "page-components.contact-component":
            return (
              <>

                <ContactComponent
                  Title={item.Title}
                  Address={item.Address}
                  HTML={item.HTML} />
              </>
            )


          default:
            return ("")
        }

      })}




      {/* {data.strapiHomepage?.PageComponents?.map((item, index) => {


        switch (item.strapi_component) {

          case "page-components.header":
            return (
              <>

                <Header
                  Background={GetImgUrl(item.Background?.localFile___NODE, pageContext.images)}
                  Title={item.Title}
                  FirstCTATitle={item.FirstCTATitle}
                  SecondCTATitle={item.SecondCTATitle}
                  ThirdCTATitle={item.ThirdCTATitle}
                />
              </>
            )
        }
      })
      } */}

      {/* {data.strapiHomepage?.PageComponents?.map((item, index) => {
        return (
          <DynamicComponent component={item.strapi_component.split(".")[1]} {...item}/>
        )
      })
      } */}



    </Layout>
  )
}

export default IndexPage